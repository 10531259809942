.container {
  width: 100%;
  height: 100%;
  padding-top: 44px;
  padding-bottom: 44px;
  background: #EBEFF5;
}

.content {
  margin-left: auto;
  margin-right: auto;
  max-width: 780px;
  border: 1px solid #1d1e4232;
  border-radius: 6px;
  padding: 44px;
  color: #141432;
}

.btn {
  width: 394px;
  height: 44px;
  border-radius: 6px;
  background: #0165B1 0% 0% no-repeat padding-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-shadow: none;
  color: #FFF;
  border: none;
  margin-top: 16px;
  margin-bottom: 16px;
  cursor: pointer;
  max-width: 350px;
  transition: all 0.2s;
}

.btn:hover {
  background: #025492;
}

.logo {
  margin-bottom: 24px;
}
