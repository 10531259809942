html,
body {
  height: 100%;
  min-height: 100vh;
}


.Toastify__toast-theme--dark {
  background: #141432;
}

.swal2-popup {
  max-width: 296px;
}

.swal2-icon {
  width: 44px;
  height: 44px;
  border-width: 0.17em;
}

.swal2-icon .swal2-icon-content {
  font-size: 28px;
}

.swal2-title {
  font-size: 14px;
}

.swal2-html-container {
  font-size: 14px;
}

.swal2-styled {
  min-width: 118px !important;
  min-height: 44px;
}


.swal2-icon.swal2-error .swal2-x-mark-line-left {
  top: 1.3em;
  width: 1.5em;
  left: 0.6em !important;
  height: 0.21em;
}

.swal2-icon.swal2-error .swal2-x-mark-line-right {
  top: 1.3em;
  width: 1.5em;
  right: 0.7em !important;
  height: 0.21em;
}
/* 
.swal2-styled.swal2-cancel {
  background-color: transparent;
} */

input:-webkit-autofill {
  box-shadow: none !important;
  -webkit-text-fill-color: inherit !important;
  caret-color: transparent !important;
}

